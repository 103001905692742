<template>
  <div>
    <v-dialog :value="true" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          {{ title }}
        </v-card-title>
        <v-card-text>{{ body }}</v-card-text>
        <v-card-text><slot></slot></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
          <v-btn color="primary" @click="$emit('confirm')"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['title', 'body'],
};
</script>

<style lang="scss" scoped></style>
